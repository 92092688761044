import {
  ConnectionProvider,
  MainProvider,
} from './context';

import { MainComponent } from './components';
import { DevicesProvider, CallProvider } from './context';


export const CallFeature = () => {
  return (
    <MainProvider>
      <DevicesProvider>
        <CallProvider>
          <ConnectionProvider>
            <MainComponent />
          </ConnectionProvider>
        </CallProvider>
      </DevicesProvider>
    </MainProvider>
  );
}
