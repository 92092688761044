import {
  FieldSelectBlock,
  FormCenteredBlock,
} from '@app/agui/blocks';

import {
  ButtonBrick,
  DialogBrick,
} from '@app/agui/bricks';

import { CardDataComposition } from '@app/agui/compositions';

import { useMainContext } from '../../context';


export const SettingsComponent = () => {
  const {
    cameraDeviceId,
    microphoneDeviceId,
    microphoneList,
    cameraList,
    microphoneSet,
    cameraSet,

    modalSettingsOpened,
    modalSettingsClose,
  } = useMainContext();

  return (
    <DialogBrick
      opened={modalSettingsOpened}
      onClose={modalSettingsClose}
      size="fullscreen"
    >
      <div className="container" style={{ paddingTop: '45px' }}>
        <CardDataComposition
          title="Настройки"
        >
          <FormCenteredBlock
            onSubmit={()=>{}}
            buttons={
              <ButtonBrick
                onClick={modalSettingsClose}
                fullWidth
                size="large"
              >
                Закрыть
              </ButtonBrick>}
          >
            {cameraDeviceId && cameraList && cameraSet && (
              <FieldSelectBlock
                name="camera"
                value={cameraDeviceId}
                onChange={(e) => cameraSet(e.target.value as string)}
                items={cameraList.map((camera) => ({ value: camera.deviceId, label: camera.label }))}
                label="Камера"
              />
            )}

            {microphoneDeviceId && microphoneList && microphoneSet && (
              <FieldSelectBlock
                name="microphone"
                value={microphoneDeviceId}
                onChange={(e) => microphoneSet(e.target.value as string)}
                items={microphoneList.map((microphone) => ({ value: microphone.deviceId, label: microphone.label }))}
                label="Микрофон"
              />
            )}
          </FormCenteredBlock>
        </CardDataComposition>
      </div>
    </DialogBrick>
  );
}
