import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ACTIONS_CALL,
  callSocket,
} from '@app/api';

import { CallConnectStatusType } from '@app/types';
import { useAppStore } from '@app/stores';

import { useMainContext } from '../main';
import { useDevicesContext } from '../devices';


export interface CallContextInterface {
  remoteMicrophoneMuted: boolean;
  remoteCameraMuted: boolean;
  connectStatus: CallConnectStatusType;
  callerReady: boolean;
  pendingText: string | undefined;
  remoteName: string | undefined;
  errorText: string;
  callStart: (event: { callUuid: string, clientIdOperator: string; operatorName: string; }) => void;
  callStop: () => void;
  callerCallStop: () => void;
  operatorCallStop: () => void;
  operatorCallReject: () => void;
  iceCandidateSet: (event: { iceCandidate: RTCIceCandidate }) => void;
  offerSet: (event: { offer: RTCSessionDescriptionInit; }) => void;
  cameraToggleAndSend: () => void;
  microphoneToggleAndSend: () => void;
  operatorDevicesSet: (event: { cameraMuted: boolean; microphoneMuted: boolean; }) => void;
  operatorLeave: () => void;
  operatorDisconnect: () => void;
  updateQueueData: (event: {personalQueueIdx: number; operatorListLen: number;}) => void;
};

export const useCallHook = (
): CallContextInterface => {
  const {
    callUuid,
    localMediaStream,
    peerMediaElements,
    peerConnection,
  } = useMainContext();
  
  const {
    cameraMuted,
    microphoneMuted,
    mediaStart,
    mediaStop,
    cameraToggle,
    microphoneToggle,
  } = useDevicesContext();

  const navigate = useNavigate();
  const { notifyCall } = useAppStore();
  const [pendingText, pendingTextSet] = React.useState<string | undefined>(undefined);
  const [connectStatus, connectStatusSet] = React.useState<CallConnectStatusType>('pending');
  const [callerReady, callerReadySet] = React.useState(false);
  const [remoteMicrophoneMuted, remoteMicrophoneMutedSet] = React.useState(false);
  const [remoteCameraMuted, remoteCameraMutedSet] = React.useState(false);
  const [remoteName, remoteNameSet] = React.useState<undefined | string>(undefined);
  const [errorText, errorTextSet] = React.useState<string>('');

  const callerPrepare = React.useCallback(async () => {
    await mediaStart();
    callerReadySet(true);
  }, [
    mediaStart,
  ]);

  const callStart = React.useCallback((event: { clientIdOperator: string; operatorName: string; }) => {
    const { clientIdOperator, operatorName } = event;
    remoteNameSet(operatorName);

    
    if (localMediaStream.current === null) return console.log('callStart - localMediaStream is null');

    peerConnection.current = new RTCPeerConnection({
      iceServers: [{
        urls: ['stun:80.90.189.135:3478']
      }],
    });

    try {
      for (const track of localMediaStream.current.getTracks()) {
        peerConnection.current.addTrack(track, localMediaStream.current);
      }
    } catch (error) {
      console.error(error);      
    }

    peerConnection.current.ontrack = ((event: RTCTrackEvent) => {
      event.track.onunmute = () => {
        peerMediaElements.current['remote']!.srcObject = event.streams[0];
      }
    });

    peerConnection.current.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
      callSocket.emit(ACTIONS_CALL.RELAY_ICE, {
        iceCandidate: event.candidate,
        clientId: clientIdOperator,
      });
    };

    peerConnection.current.onconnectionstatechange = (e) => {
      if (peerConnection.current === null) return;
      const { connectionState } = peerConnection.current;
      const status = connectionState === 'connected' || connectionState === 'connecting'
        ? connectionState
        : connectionState === 'disconnected'
          ? 'connecting'
          : 'pending';

      connectStatusSet(status)
    } 
  }, [
    localMediaStream,
    peerConnection,
    peerMediaElements,
  ]);

  const callStop = React.useCallback(() => {
    mediaStop();
    navigate('/');
  }, [
    navigate,
    mediaStop,
  ]);

  const callerCallStop = React.useCallback(() => {
    callStop();
    callSocket.emit(ACTIONS_CALL.CALLER_CALL_STOP, { callUuid });
  }, [
    callUuid,
    callStop,
  ]);

  const offerSet = React.useCallback(async (event: { offer: RTCSessionDescriptionInit; }) => {
    if (peerConnection.current === null) return console.log('offerSet - peerConnection is null');

    await peerConnection.current.setRemoteDescription(
      new RTCSessionDescription(event.offer),
    );

    const answer = await peerConnection.current.createAnswer();
    await peerConnection.current.setLocalDescription(answer);

    callSocket.emit(ACTIONS_CALL.CALLER_SEND_ANSWER, {
      callUuid,
      answer,
    });
  }, [
    callUuid,
    peerConnection,
  ]);

  const iceCandidateSet = React.useCallback((event: { iceCandidate: RTCIceCandidate }) => {
    if (!peerConnection.current || !peerConnection.current.remoteDescription) return;

    try {
      peerConnection.current!.addIceCandidate(event.iceCandidate);
    } catch (error) {
      console.log(error);
    }
  }, [
    peerConnection,
  ]);

  const operatorCallStop = React.useCallback(() => {
    notifyCall({
      type: 'info',
      message: 'Оператор закончил звонок',
    });
    callStop();
  }, [
    notifyCall,
    callStop,
  ]);

  const operatorLeave = React.useCallback(() => {
    errorTextSet('Оператор покинул беседу');
    connectStatusSet('pending');
  }, [
  ]);

  const operatorDisconnect = React.useCallback(() => {
    errorTextSet('Потеряна связь с оператором');
    connectStatusSet('connecting');
  }, [
  ]);

  const cameraToggleAndSend = React.useCallback(() => {
    cameraToggle();
    callSocket.emit(ACTIONS_CALL.CALLER_DEVICES_SET, {
      callUuid,
      cameraMuted: !cameraMuted,
      microphoneMuted,
    });
  }, [
    callUuid,
    cameraMuted,
    microphoneMuted,
    cameraToggle,
  ]);

  const microphoneToggleAndSend = React.useCallback(() => {
    callSocket.emit(ACTIONS_CALL.CALLER_DEVICES_SET, {
      callUuid,
      cameraMuted,
      microphoneMuted: !microphoneMuted,
    });
    microphoneToggle();
  }, [
    callUuid,
    cameraMuted,
    microphoneMuted,
    microphoneToggle,
  ]);

  const operatorCallReject = React.useCallback(() => {
    notifyCall({
      type: 'info',
      message: 'Оператор отклонил звонок',
    });
    callStop();
  }, [
    notifyCall,
    callStop,
  ]);

  const operatorDevicesSet = React.useCallback((event: { cameraMuted: boolean; microphoneMuted: boolean; }) => {
    remoteCameraMutedSet(event.cameraMuted);
    remoteMicrophoneMutedSet(event.microphoneMuted);
  }, []);

  const updateQueueData = React.useCallback((event: {personalQueueIdx: number; operatorListLen: number;}) => {
    pendingTextSet(`Операторов в сети: ${event.operatorListLen}, Человек перед вами: ${event.personalQueueIdx}`);
  }, []);

  React.useEffect(() => {
    if (connectStatus === 'connected') {
      callSocket.emit(ACTIONS_CALL.CALLER_DEVICES_SET, {
        callUuid,
        cameraMuted,
        microphoneMuted,
      });

      pendingTextSet(undefined);
      errorTextSet('');
    }
  }, [
    connectStatus,
    callUuid,
    cameraMuted,
    microphoneMuted,
  ]);

  React.useEffect(() => {
    callerPrepare();

    return () => {
      mediaStop();
    }
  }, [
    callerPrepare,
    mediaStop,
  ]);

  return React.useMemo(() => ({
    pendingText,
    connectStatus,
    callerReady,
    remoteMicrophoneMuted,
    remoteCameraMuted,
    remoteName,
    errorText,
    callerCallStop,
    operatorDevicesSet,
    callStart,
    callStop,
    operatorCallStop,
    operatorCallReject,
    iceCandidateSet,
    offerSet,
    cameraToggleAndSend,
    microphoneToggleAndSend,
    operatorLeave,
    operatorDisconnect,
    updateQueueData,
  }), [
    pendingText,
    connectStatus,
    callerReady,
    remoteMicrophoneMuted,
    remoteCameraMuted,
    remoteName,
    errorText,
    callerCallStop,
    operatorDevicesSet,
    callStart,
    callStop,
    operatorCallStop,
    operatorCallReject,
    iceCandidateSet,
    offerSet,
    cameraToggleAndSend,
    microphoneToggleAndSend,
    operatorLeave,
    operatorDisconnect,
    updateQueueData,
  ]);
}
