import {
  ButtonIconBrick,
  ChipBrick,
} from '@app/agui/bricks';

import {
  CallEndIcon,
  CameraIcon,
  CameraOffIcon,
  MicrophoneIcon,
  MicrophoneOffIcon,
  SettingsIcon,
} from '@app/agui/icons';

import {
  ButtonConfirmComposition, 
} from '@app/agui/compositions';

import { useMainContext } from '../../context';
import styles from './controls.module.scss';


export const ControlsComponent = () => {
  const {
    localCameraMuted,
    localMicrophoneMuted,
    remoteCameraMuted,
    remoteMicrophoneMuted,
    microphoneList,
    cameraList,
    extra,
    errorText,
    microphoneToggle,
    cameraToggle,
    callStop,
    microphoneSet,
    cameraSet,
    modalSettingsOpen,
  } = useMainContext();

  return (
    <div className={styles['controls']}>
      <div className={styles['controls__wrapper']}>
        <div className={styles['controls__info']}>
          {errorText && <ChipBrick color="error" label={errorText} />}
          {!errorText && localMicrophoneMuted && <ChipBrick color="warning" label="Вас не слышно, включите микрофон"/>}
          {!errorText && localCameraMuted && <ChipBrick color="warning" label="Вас не видно, включите камеру"/>}
          {!errorText && remoteMicrophoneMuted && <ChipBrick color="warning" label="Собеседник выключил микрофон"/>}
          {!errorText && remoteCameraMuted && <ChipBrick color="warning" label="Собеседник выключил камеру"/>}
        </div>
        <div className={styles['controls__block']}>
          {microphoneToggle && (
            <ButtonIconBrick
              size="large"
              onClick={microphoneToggle}
            >
              {localMicrophoneMuted ? <MicrophoneOffIcon /> : <MicrophoneIcon />}
            </ButtonIconBrick>
          )}
          {cameraToggle && (
            <ButtonIconBrick
              size="large"
              onClick={cameraToggle}
            >
              {localCameraMuted ? <CameraOffIcon /> : <CameraIcon />}
            </ButtonIconBrick>
          )}
          {callStop && (
            <ButtonConfirmComposition
              type="icon"
              onClick={() => callStop()}
              message="Завершить звонок?"
              buttonText="Завершить звонок"
              icon={<CallEndIcon />}
              size="large"
              color="error"
            />
          )}
          {extra && extra.length && (
            extra.map((item, index) => {
              return (
                <ButtonIconBrick
                  size="large"
                  onClick={item.cb}
                  key={index}
                >
                  {item.icon}
                </ButtonIconBrick>
              );
            })
          )}
          {((microphoneSet && microphoneList) || (cameraSet && cameraList)) && (
            <ButtonIconBrick
              size="large"
              onClick={modalSettingsOpen}
            >
              <SettingsIcon />
            </ButtonIconBrick>
          )}
        </div>
      </div>
    </div>
  );
}
