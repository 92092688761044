import React from 'react';
import { useParams } from 'react-router-dom';


export interface MainContextInterface {
  callUuid: string | undefined;
  peerConnection: React.MutableRefObject<RTCPeerConnection | null>;
  localMediaStream: React.MutableRefObject<MediaStream | null>;
  peerMediaElements: React.MutableRefObject<{ [key: string]: HTMLVideoElement | null; }>
};

export const useMainHook = (
): MainContextInterface => {
  const { uuid: callUuid } = useParams();

  const peerConnection = React.useRef<RTCPeerConnection | null>(null);
  const localMediaStream = React.useRef<MediaStream | null>(null);
  const peerMediaElements = React.useRef<{ [key: string]: HTMLVideoElement | null }>({});


  return React.useMemo(() => ({
    callUuid,
    peerConnection,
    localMediaStream,
    peerMediaElements,
  }), [
    callUuid,
    peerConnection,
    localMediaStream,
    peerMediaElements,
  ]);
};
