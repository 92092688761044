import { MainComponent } from './components';

import {
  ConnectionProvider,
  MainProvider,
} from './context';


export const FormQueueUpFeature = () => {
  return (
    <MainProvider>
      <ConnectionProvider>
        <MainComponent />
      </ConnectionProvider>        
    </MainProvider>
  );
}
