import * as React from 'react';
import { MainProps } from './main.props';
import { CallConnectStatusType } from '@app/types';


export type MainContextInterface = Omit<MainProps, 'children'> & {
  modalSettingsOpened: boolean;
  modalSettingsOpen: () => void;
  modalSettingsClose: () => void;
  provideMediaRef: (id: string, node: HTMLVideoElement | null) => void;
  connectStatus: CallConnectStatusType;
  extra?: { icon: React.ReactNode; cb: () => void; }[];
  pendingText?: string;
  errorText?: string;
}

export const useMainHook = ({
  peerMediaElements,
  remoteName,
  localMicrophoneMuted,
  localCameraMuted,
  remoteMicrophoneMuted,
  remoteCameraMuted,
  cameraDeviceId,
  microphoneDeviceId,
  cameraList,
  microphoneList,
  connectStatus,
  pendingText,
  extra,
  errorText,
  microphoneToggle,
  cameraToggle,
  callStop,
  cameraSet,
  microphoneSet,
}: MainProps): MainContextInterface => {
  const [ modalSettingsOpened, modalSettingsOpenedSet ] = React.useState(false);

  const modalSettingsOpen = React.useCallback(() => {
    modalSettingsOpenedSet(true);
  }, []);

  const modalSettingsClose = React.useCallback(() => {
    modalSettingsOpenedSet(false);
  }, []);

  const provideMediaRef = React.useCallback((id: string, node: HTMLVideoElement | null) => {
    peerMediaElements.current[id] = node;
  }, [
    peerMediaElements,
  ]);
  
  const resizeWindow = React.useCallback(() => {
    const height = window.innerHeight;
    console.log('resize', height, window.screen.availHeight);
    // console.log('resize', height, document.documentElement.clientHeight, document.body.clientHeight);

    const wrapper = document.getElementById('call-wrapper');
    if (wrapper) wrapper.style.height = String(height) + 'px';
  }, []);

  React.useEffect(() => {
    setTimeout(resizeWindow, 1000);
    setTimeout(resizeWindow, 3000);
    window.addEventListener('resize', resizeWindow);

    return () => {
      window.removeEventListener('resize', resizeWindow);
    }
  }, [
    connectStatus,
    resizeWindow,
  ]);

  return React.useMemo(() => ({
    peerMediaElements,
    modalSettingsOpened,
    modalSettingsOpen,
    modalSettingsClose,
    provideMediaRef,

    // props
    remoteName,
    localMicrophoneMuted,
    localCameraMuted,
    remoteMicrophoneMuted,
    remoteCameraMuted,
    cameraDeviceId,
    microphoneDeviceId,
    cameraList,
    microphoneList,
    connectStatus,
    pendingText,
    extra,
    errorText,
    microphoneToggle,
    cameraToggle,
    callStop,
    cameraSet,
    microphoneSet,
  }), [
    peerMediaElements,
    modalSettingsOpened,
    modalSettingsOpen,
    modalSettingsClose,
    provideMediaRef,

    // props
    remoteName,
    localMicrophoneMuted,
    localCameraMuted,
    remoteMicrophoneMuted,
    remoteCameraMuted,
    cameraDeviceId,
    microphoneDeviceId,
    cameraList,
    microphoneList,
    connectStatus,
    pendingText,
    extra,
    errorText,
    microphoneToggle,
    cameraToggle,
    callStop,
    cameraSet,
    microphoneSet,
  ]);
}
