import {
  TransitionOpacityBrick,
} from '@app/components';

import {
  CallSmart,
} from '@app/components/smarts/call/call.smart';

import {
  useCallContext,
  useDevicesContext,
  useMainContext,
} from '../../context';


export const MainComponent = () => {
  const { peerMediaElements } = useMainContext();
  
  const {
    connectStatus,
    remoteCameraMuted,
    remoteMicrophoneMuted,
    remoteName,
    errorText,
    microphoneToggleAndSend,
    cameraToggleAndSend,
    consultationStop,
  } = useCallContext();
  
  const {
    microphoneMuted,
    cameraMuted,
    cameraDeviceId,
    microphoneDeviceId,
    cameraList,
    microphoneList,
    cameraSet,
    microphoneSet,
  } = useDevicesContext();
  
  return (
    <TransitionOpacityBrick>
      <CallSmart
        remoteName={remoteName}
        peerMediaElements={peerMediaElements}
        connectStatus={connectStatus}
        localMicrophoneMuted={microphoneMuted}
        localCameraMuted={cameraMuted}
        remoteMicrophoneMuted={remoteMicrophoneMuted}
        remoteCameraMuted={remoteCameraMuted}
        cameraDeviceId={cameraDeviceId}
        microphoneDeviceId={microphoneDeviceId}
        cameraList={cameraList}
        microphoneList={microphoneList}
        microphoneToggle={microphoneToggleAndSend}
        cameraToggle={cameraToggleAndSend}
        cameraSet={cameraSet}
        microphoneSet={microphoneSet}
        callStop={consultationStop}
        errorText={errorText}
      />
    </TransitionOpacityBrick>
  );
}
