import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ACTIONS_CONSULTATIONS,
  consultationSocket,
} from '@app/api';

import { CallConnectStatusType } from '@app/types';

import { useMainContext } from '../main';
import { useDevicesContext } from '../devices';


export interface CallContextInterface {
  callerReady: boolean;
  connectStatus: CallConnectStatusType;
  remoteMicrophoneMuted: boolean;
  remoteCameraMuted: boolean;
  remoteName: string | undefined;
  errorText: string;
  cameraToggleAndSend: () => void;
  microphoneToggleAndSend: () => void;
  consultantDevicesSet: (event: { cameraMuted: boolean; microphoneMuted: boolean; }) => void;
  consultationStart: (e: { clientIdConsultant: string; consultantName: string; }) => void;
  consultationStop: () => void;
  iceCandidateSet: (event: { iceCandidate: RTCIceCandidate }) => void;
  offerSet: (e: { offer: RTCSessionDescriptionInit; }) => void;
  consultantLeave: () => void;
  consultantDisconnect: () => void;
};

export const useCallHook = (
): CallContextInterface => {
  const {
    consultationUuid,
    localMediaStream,
    peerMediaElements,
    peerConnection,
  } = useMainContext();
  
  const {
    cameraMuted,
    microphoneMuted,
    mediaStart,
    mediaStop,
    cameraToggle,
    microphoneToggle,
  } = useDevicesContext();

  const navigate = useNavigate();
  const [connectStatus, connectStatusSet] = React.useState<CallConnectStatusType>('pending');
  const [callerReady, callerReadySet] = React.useState(false);
  const [remoteMicrophoneMuted, remoteMicrophoneMutedSet] = React.useState(false);
  const [remoteCameraMuted, remoteCameraMutedSet] = React.useState(false);
  const [remoteName, remoteNameSet] = React.useState<string | undefined>(undefined);
  const [errorText, errorTextSet] = React.useState('');

  const callerPrepare = React.useCallback(async () => {
    await mediaStart();
    callerReadySet(true);
  }, [
    mediaStart,
  ]);

  const consultationStart = React.useCallback((e: { clientIdConsultant: string; consultantName: string; }) => {
    const { clientIdConsultant, consultantName } = e;
    remoteNameSet(consultantName);
    
    if (localMediaStream.current === null) return console.log('consultationStart - localMediaStream is null');

    peerConnection.current = new RTCPeerConnection({
      iceServers: [{
        urls: ['stun:80.90.189.135:3478']
      }],
    });

    try {
      for (const track of localMediaStream.current.getTracks()) {
        peerConnection.current.addTrack(track, localMediaStream.current);
      }
    } catch (error) {
      console.error(error);      
    }

    peerConnection.current.ontrack = ((event: RTCTrackEvent) => {
      event.track.onunmute = () => {
        peerMediaElements.current['remote']!.srcObject = event.streams[0];
      }
    });

    peerConnection.current.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
      consultationSocket.emit(ACTIONS_CONSULTATIONS.RELAY_ICE, {
        iceCandidate: event.candidate,
        clientId: clientIdConsultant,
      });
    };

    peerConnection.current.onconnectionstatechange = (e) => {
      if (peerConnection.current === null) return;
      const { connectionState } = peerConnection.current;
      const status = connectionState === 'connected' || connectionState === 'connecting'
        ? connectionState
        : connectionState === 'disconnected'
          ? 'connecting'
          : 'pending';

      if (status === 'connected') errorTextSet('');
      connectStatusSet(status)
    } 
  }, [
    localMediaStream,
    peerConnection,
    peerMediaElements,
  ]);

  const consultationStop = React.useCallback(() => {
    mediaStop();
    navigate('/');
    consultationSocket.emit(ACTIONS_CONSULTATIONS.CALLER_LEAVE);
  }, [
    navigate,
    mediaStop,
  ]);

  const consultantLeave = React.useCallback(() => {
    errorTextSet('Консультант покинул беседу');
    connectStatusSet('pending');
  }, [
  ]);

  const consultantDisconnect = React.useCallback(() => {
    errorTextSet('Потеряна связь с консультантом');
    connectStatusSet('connecting');
  }, [
  ]);

  const offerSet = React.useCallback(async (e: { offer: RTCSessionDescriptionInit; }) => {
    if (peerConnection.current === null) return console.log('offerSet - peerConnection is null');

    await peerConnection.current.setRemoteDescription(
      new RTCSessionDescription(e.offer),
    );

    const answer = await peerConnection.current.createAnswer();
    await peerConnection.current.setLocalDescription(answer);

    consultationSocket.emit(ACTIONS_CONSULTATIONS.CALLER_SEND_ANSWER, {
      consultationUuid,
      answer,
    });
  }, [
    consultationUuid,
    peerConnection,
  ]);

  const cameraToggleAndSend = React.useCallback(() => {
    cameraToggle();
    consultationSocket.emit(ACTIONS_CONSULTATIONS.CALLER_DEVICES_SET, {
      consultationUuid,
      cameraMuted: !cameraMuted,
      microphoneMuted,
    });
  }, [
    consultationUuid,
    cameraMuted,
    microphoneMuted,
    cameraToggle,
  ]);

  const microphoneToggleAndSend = React.useCallback(() => {
    consultationSocket.emit(ACTIONS_CONSULTATIONS.CALLER_DEVICES_SET, {
      consultationUuid,
      cameraMuted,
      microphoneMuted: !microphoneMuted,
    });
    microphoneToggle();
  }, [
    consultationUuid,
    cameraMuted,
    microphoneMuted,
    microphoneToggle,
  ]);

  const iceCandidateSet = React.useCallback((event: { iceCandidate: RTCIceCandidate }) => {
    if (!peerConnection.current || !peerConnection.current.remoteDescription) return;

    try {
      peerConnection.current!.addIceCandidate(event.iceCandidate);
    } catch (error) {
      console.log(error);
    }
  }, [
    peerConnection,
  ]);

  const consultantDevicesSet = React.useCallback((event: { cameraMuted: boolean; microphoneMuted: boolean; }) => {
    remoteCameraMutedSet(event.cameraMuted);
    remoteMicrophoneMutedSet(event.microphoneMuted);
  }, []);

  React.useEffect(() => {
    if (connectStatus === 'connected') {
      consultationSocket.emit(ACTIONS_CONSULTATIONS.CALLER_DEVICES_SET, {
        consultationUuid,
        cameraMuted,
        microphoneMuted,
      });
    }
  }, [
    connectStatus,
    consultationUuid,
    cameraMuted,
    microphoneMuted,
  ]);

  React.useEffect(() => {
    callerPrepare();

    return () => {
      mediaStop();
    }
  }, [
    callerPrepare,
    mediaStop,
  ]);

  return React.useMemo(() => ({
    callerReady,
    connectStatus,
    remoteMicrophoneMuted,
    remoteCameraMuted,
    remoteName,
    errorText,
    consultantDevicesSet,
    consultationStart,
    consultationStop,
    iceCandidateSet,
    offerSet,
    cameraToggleAndSend,
    microphoneToggleAndSend,
    consultantLeave,
    consultantDisconnect,
  }), [
    callerReady,
    connectStatus,
    remoteMicrophoneMuted,
    remoteCameraMuted,
    remoteName,
    errorText,
    consultantDevicesSet,
    consultationStart,
    consultationStop,
    iceCandidateSet,
    offerSet,
    cameraToggleAndSend,
    microphoneToggleAndSend,
    consultantLeave,
    consultantDisconnect,
  ]);
}
