import { MainComponent } from './components';

import {
  CallProvider,
  ConnectionProvider,
  DevicesProvider,
  MainProvider,
} from './context';


export const ConsultationFeature = () => {
  return (
    <MainProvider>
      <DevicesProvider>
        <CallProvider>
          <ConnectionProvider>
            <MainComponent />
          </ConnectionProvider>
        </CallProvider>
      </DevicesProvider>
    </MainProvider>
  );
};
