import { ProgressCircularBrick, TypographyBrick } from '@app/agui/bricks';

import { useMainContext } from '../../context';
import styles from './video-remote.module.scss';


export const VideoRemoteComponent = () => {
  const {
    remoteName,
    connectStatus,
    pendingText,
    provideMediaRef,
  } = useMainContext();

  return (
    <div className={styles['video-remote']}>
      {remoteName && (
        <div className={styles['video-remote__caller-name-wrapper']}>
          <div className={styles['video-remote__caller-name']}>{remoteName}</div>
        </div>
      )}
      
      <video
        className={styles['video-remote__tag']}
        id="video-remote"
        width="100%"
        autoPlay
        playsInline
        ref={(node) => {
          provideMediaRef('remote', node);
        }}
      />

      {connectStatus !== 'connected' && (
        <div className={styles['video-remote__status-connecting']}>
          <div style={{ textAlign: 'center'}}>
            <ProgressCircularBrick size={60} />
            {connectStatus === 'connecting' && (
              <TypographyBrick variant="h6">Подключение</TypographyBrick>
            )}
            {(connectStatus === 'pending' && pendingText === undefined) && (
              <TypographyBrick variant="h6">Ожидание</TypographyBrick>
            )}
            {(connectStatus === 'pending' && pendingText !== undefined) && (
              <TypographyBrick variant="h6">{pendingText}</TypographyBrick>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
