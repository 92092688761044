import { useMainContext } from '../../context';
import styles from './video-local.module.scss';


export const VideoLocalComponent = () => {
  const {
    localCameraMuted,
    provideMediaRef,
  } = useMainContext();

  return (
    <div className={styles['video-local']}>
      <video
        id="video-local"
        className={styles['video-local__tag']}
        width="100%"
        autoPlay
        playsInline
        muted={true}
        ref={(node) => {
          provideMediaRef('local', node);
        }}
        hidden={localCameraMuted}
      />
    </div>
  );
}
