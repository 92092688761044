export const ACTIONS_CONSULTATIONS = {
  // EMIT
  CALLER_CONNECT: 'CALLER_CONNECT',
  CALLER_LEAVE: 'CALLER_LEAVE',
  CALLER_SEND_ANSWER: 'CALLER_SEND_ANSWER',
  CALLER_DEVICES_SET: 'CALLER_DEVICES_SET',
  RELAY_ICE: 'RELAY_ICE',

  // LISTEN
  OFFER_TO_CALLER_SEND: 'OFFER_TO_CALLER_SEND',
  CALL_READY: 'CALL_READY',
  ICE_CANDIDATE: 'ICE_CANDIDATE',
  CONSULTANT_DEVICES_SET: 'CONSULTANT_DEVICES_SET',
  CONSULTANT_DISCONNECT: 'CONSULTANT_DISCONNECT',
  CONSULTANT_LEAVE: 'CONSULTANT_LEAVE',
};