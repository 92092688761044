export const ACTIONS_CALL = {
  // LISTEN
  DATA_GENERAL_SEND: 'DATA_GENERAL_SEND',
  DATA_TO_CALLER_SEND: 'DATA_TO_CALLER_SEND',
  CALLER_QUEUE_UP_SUCCESS: 'CALLER_QUEUE_UP_SUCCESS',
  CALL_READY: 'CALL_READY',
  OPERATOR_CALL_STOP: 'OPERATOR_CALL_STOP',
  OPERATOR_CALL_REJECT: 'OPERATOR_CALL_REJECT',
  OPERATOR_DEVICES_SET: 'OPERATOR_DEVICES_SET',
  OPERATOR_LEAVE: 'OPERATOR_LEAVE',
  OPERATOR_DISCONNECT: 'OPERATOR_DISCONNECT',
  
  
  OFFER_TO_CALLER_SEND: 'OFFER_TO_CALLER_SEND',
  OPERATOR_CALL_START: 'OPERATOR_CALL_START',
  ICE_CANDIDATE: 'ICE_CANDIDATE',
  CLIENT_DISCONNECT: 'CLIENT_DISCONNECT',
  
  // EMIT
  CALLER_QUEUE_UP: 'CALLER_QUEUE_UP',
  CALLER_READY: 'CALLER_READY',
  CALLER_LEAVE: 'CALLER_LEAVE',
  CALLER_CALL_STOP: 'CALLER_CALL_STOP',
  CALLER_DEVICES_SET: 'CALLER_DEVICES_SET',


  CALLER_CONNECT: 'CALLER_CONNECT',
  CALLER_SEND_ANSWER: 'CALLER_SEND_ANSWER',
  RELAY_ICE: 'RELAY_ICE',
};
