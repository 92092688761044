import React from 'react';

import {
  callSocket,
  ACTIONS_CALL,
} from '@app/api';

import { useMainContext } from '../main';
import { useCallContext } from '../call';


export interface ConnectionContextInterface {
};

export const useConnectionHook = (
): ConnectionContextInterface => {
  const { callUuid } = useMainContext();
  const {
    callerReady,
    updateQueueData,
    offerSet,
    iceCandidateSet,
    callStart,
    operatorCallStop,
    operatorCallReject,
    operatorDevicesSet,
    operatorDisconnect,
    operatorLeave,
  } = useCallContext()

  React.useEffect(() => {
    if (callerReady) {
      callSocket.emit(ACTIONS_CALL.CALLER_READY, { callUuid });
    }

    return () => {
      if (callerReady) callSocket.emit(ACTIONS_CALL.CALLER_LEAVE);
    }
  }, [
    callUuid,
    callerReady,
  ]);

  React.useEffect(() => {
    callSocket.on(ACTIONS_CALL.CALL_READY, callStart);
    return () => {callSocket.off(ACTIONS_CALL.CALL_READY, callStart)};
  }, [callStart]);

  React.useEffect(() => {
    callSocket.on(ACTIONS_CALL.ICE_CANDIDATE, iceCandidateSet);
    return () => {callSocket.off(ACTIONS_CALL.ICE_CANDIDATE, iceCandidateSet);};
  }, [iceCandidateSet]);

  React.useEffect(() => {
    callSocket.on(ACTIONS_CALL.OFFER_TO_CALLER_SEND, offerSet);
    return () => {callSocket.off(ACTIONS_CALL.OFFER_TO_CALLER_SEND, offerSet)};
  }, [offerSet]);

  React.useEffect(() => {
    callSocket.on(ACTIONS_CALL.OPERATOR_CALL_STOP, operatorCallStop);
    return () => {callSocket.off(ACTIONS_CALL.OPERATOR_CALL_STOP, operatorCallStop)};
  }, [operatorCallStop]);

  React.useEffect(() => {
    callSocket.on(ACTIONS_CALL.OPERATOR_CALL_REJECT, operatorCallReject);
    return () => {callSocket.off(ACTIONS_CALL.OPERATOR_CALL_REJECT, operatorCallReject)};
  }, [operatorCallReject]);

  React.useEffect(() => {
    callSocket.on(ACTIONS_CALL.DATA_TO_CALLER_SEND, updateQueueData);
    return () => {callSocket.off(ACTIONS_CALL.DATA_TO_CALLER_SEND, updateQueueData)};
  }, [updateQueueData]);

  React.useEffect(() => {
    callSocket.on(ACTIONS_CALL.OPERATOR_DEVICES_SET, operatorDevicesSet);
    return () => {callSocket.off(ACTIONS_CALL.OPERATOR_DEVICES_SET, operatorDevicesSet)};
  }, [operatorDevicesSet]);

  React.useEffect(() => {
    callSocket.on(ACTIONS_CALL.OPERATOR_DISCONNECT, operatorDisconnect);
    return () => {callSocket.off(ACTIONS_CALL.OPERATOR_DISCONNECT, operatorDisconnect)};
  }, [operatorDisconnect]);

  React.useEffect(() => {
    callSocket.on(ACTIONS_CALL.OPERATOR_LEAVE, operatorLeave);
    return () => {callSocket.off(ACTIONS_CALL.OPERATOR_LEAVE, operatorLeave)};
  }, [operatorLeave]);
  
  return React.useMemo(() => ({
  }), [
  ]);
};
