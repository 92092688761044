import { MainComponent } from './components';
import { MainProvider } from './context';
import { CallProps } from './call.props';


export const CallSmart = (props: CallProps) => {
  return (
    <MainProvider
      {...props}
    >
      <MainComponent />
    </MainProvider>
  );
};
