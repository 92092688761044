import { ControlsComponent } from '../controls';
import { SettingsComponent } from '../settings';
import { VideoLocalComponent } from '../video-local';
import { VideoRemoteComponent } from '../video-remote';
import { WrapperComponent } from '../wrapper';


export const MainComponent = () => {
  return (
    <WrapperComponent>
      <VideoLocalComponent />
      <VideoRemoteComponent />
      <ControlsComponent />
      <SettingsComponent />
    </WrapperComponent>
  );
}
