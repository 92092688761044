import {
  TransitionOpacityBrick,
} from '@app/components';

import {
  CallSmart,
} from '@app/components/smarts/call';

import { useCallContext, useDevicesContext, useMainContext } from '../../context';


export const MainComponent = () => {
  const {
    peerMediaElements,
  } = useMainContext();
  
  const {
    pendingText,
    connectStatus,
    remoteCameraMuted,
    remoteMicrophoneMuted,
    remoteName,
    errorText,
    cameraToggleAndSend,
    microphoneToggleAndSend,
    callerCallStop,
  } = useCallContext();

  const {
    microphoneMuted,
    cameraMuted,
    cameraDeviceId,
    microphoneDeviceId,
    cameraList,
    microphoneList,
    microphoneSet,
    cameraSet,
  } = useDevicesContext();

  return (
    <TransitionOpacityBrick>
      <CallSmart
        remoteName={remoteName}
        peerMediaElements={peerMediaElements}
        connectStatus={connectStatus}
        localMicrophoneMuted={microphoneMuted}
        localCameraMuted={cameraMuted}
        remoteMicrophoneMuted={remoteMicrophoneMuted}
        remoteCameraMuted={remoteCameraMuted}
        cameraDeviceId={cameraDeviceId}
        microphoneDeviceId={microphoneDeviceId}
        cameraList={cameraList}
        microphoneList={microphoneList}
        microphoneToggle={microphoneToggleAndSend}
        cameraToggle={cameraToggleAndSend}
        cameraSet={cameraSet}
        microphoneSet={microphoneSet}
        callStop={callerCallStop}
        pendingText={pendingText}
        errorText={errorText}
      />
    </TransitionOpacityBrick>
  );
};
