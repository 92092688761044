import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  BackendErrorsType,
  ChangeFieldEventType,
} from '@app/types';

import { translateErrorHelper } from '@app/helpers';
import { ACTIONS_CALL, callSocket } from '@app/api';
import { useAppStore } from '@app/stores';

import {
  DataGeneralSetEvent,
  QueueUpSuccessEvent,
} from './types';


export interface MainContextInterface {
  operatorListLen: number;
  queueLen: number;
  formDirty: boolean;
  formIsSubmitting: boolean;
  formValue: typeof initialFormValue;
  changeFormValue: (e: ChangeFieldEventType) => void;
  onSubmit: (e: React.SyntheticEvent) => Promise<void>;
  errorSet: (fieldName: string) => string | null;
  dataGeneralSet: (e: DataGeneralSetEvent) => void;
  queueUpSuccess: (e: QueueUpSuccessEvent) => void;
};

const initialFormValue = {
  lastName: '',
  firstName: '',
  secondName: '',
  phone: '',
  snils: '',
};

export const useMainHook = (
): MainContextInterface => {
  const navigate = useNavigate();
  const { notifyCall } = useAppStore();

  const [operatorListLen, operatorListLenSet] = React.useState(0);
  const [queueLen, queueLenSet] = React.useState(0);
  const [formValue, formValueSet] = React.useState(initialFormValue);
  const [formDirty, formDirtySet] = React.useState(false);
  const [formSended, formSendedSet] = React.useState(false);
  const [formIsSubmitting, formIsSubmittingSet] = React.useState(false);
  const [errors, errorsSet] = React.useState<BackendErrorsType | null>(null);

  const dataGeneralSet = React.useCallback((e: DataGeneralSetEvent) => {
    operatorListLenSet(e.operatorListLen);
    queueLenSet(e.callListLen);
  }, []);

  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => {
      return {
        ...state,
        [e.target.name]: e.target.value,
      };
    });
  }, []);


  const validateForm = React.useCallback((): boolean => {
    errorsSet(null);
    let hasErrors = false;

    if (formValue.firstName.length === 0) {
      hasErrors = true;
      errorsSet((state) => {
        if (state === null) return {
          firstName: ['should not be empty'],
        };
        return {
          ...state,
          firstName: ['should not be empty'],
        }
      })
    }

    if (formValue.phone.length === 0) {
      hasErrors = true;
      errorsSet((state) => {
        if (state === null) return {
          phone: ['should not be empty'],
        };
        return {
          ...state,
          phone: ['should not be empty'],
        }
      })
    }

    if (formValue.phone.length !== 0 && formValue.phone.length < 12) {
      hasErrors = true;
      errorsSet((state) => {
        if (state === null) return {
          phone: ['not valid phone'],
        };
        return {
          ...state,
          phone: ['not valid phone'],
        }
      })
    }

    if (formValue.snils.length === 0) {
      hasErrors = true;
      errorsSet((state) => {
        if (state === null) return {
          snils: ['should not be empty'],
        };
        return {
          ...state,
          snils: ['should not be empty'],
        }
      })
    }

    if (formValue.snils.length !== 0 && formValue.snils.length < 14) {
      hasErrors = true;
      errorsSet((state) => {
        if (state === null) return {
          snils: ['not valid snils'],
        };
        return {
          ...state,
          snils: ['not valid snils'],
        }
      })
    }

    if (formValue.lastName.length === 0) {
      hasErrors = true;
      errorsSet((state) => {
        if (state === null) return {
          lastName: ['should not be empty']
        };
        return {
          ...state,
          lastName: ['should not be empty'],
        }
      })
    }

    if (formValue.secondName.length === 0) {
      hasErrors = true;
      errorsSet((state) => {
        if (state === null) return {
          secondName: ['should not be empty']
        };
        return {
          ...state,
          secondName: ['should not be empty'],
        }
      })
    }

    return hasErrors;
  }, [
    formValue.firstName.length,
    formValue.lastName.length,
    formValue.secondName.length,
    formValue.phone.length,
    formValue.snils.length,
  ]);

  const onSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const hasError = validateForm();
    formSendedSet(true);
    formIsSubmittingSet(true);

    if (hasError) {
      formIsSubmittingSet(false);
      return notifyCall({
        type: 'error',
        message: 'Проверьте корректность введенных данных',
      })
    }

    callSocket.emit(ACTIONS_CALL.CALLER_QUEUE_UP, formValue);
  }, [
    formValue,
    validateForm,
    notifyCall,
  ]);

  const queueUpSuccess = React.useCallback((e: QueueUpSuccessEvent) => {
    formIsSubmittingSet(false);

    formValueSet(initialFormValue);
    formDirtySet(false);

    navigate('/call/' + e.callUuid);
  }, [
    navigate,
  ]);

  const errorSet = React.useCallback((
    fieldName: string,
  ): string | null => {
    return (formSended && errors && errors[fieldName])
      ? translateErrorHelper(errors[fieldName][0])
      : null;
  }, [
    formSended,
    errors,
  ]);

  return React.useMemo(() => ({
    operatorListLen,
    queueLen,
    formDirty,
    formIsSubmitting,
    formValue,
    onSubmit,
    changeFormValue,
    errorSet,
    dataGeneralSet,
    queueUpSuccess,
  }), [
    operatorListLen,
    queueLen,
    formDirty,
    formIsSubmitting,
    formValue,
    onSubmit,
    changeFormValue,
    errorSet,
    dataGeneralSet,
    queueUpSuccess,
  ]);
};
