import cs from 'classnames';

import { ProgressCircularBrick } from '@app/agui/bricks';
import styles from './progress-circular-fullpage.module.scss';
import { useAppStore } from '@app/stores';


export const ProgressCircularFullpageBlock = () => {
  const { themeMode } = useAppStore();

  const classnames = cs(
    styles['progress-circular-fullpage'],
    { [styles['progress-circular-fullpage--dark']]: themeMode === 'dark' },
  );

  return (
    <div className={classnames}>
      <ProgressCircularBrick />
    </div>
  );
};
