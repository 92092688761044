import React from 'react';

import { callSocket } from '@app/api';

import { useMainContext } from '../main';
import { ACTIONS_CALL } from '@app/api';


export interface ConnectionContextInterface {
};

export const useConnectionHook = (
): ConnectionContextInterface => {
  const {
    dataGeneralSet,
    queueUpSuccess,
  } = useMainContext();

  React.useEffect(() => {
    callSocket.on(ACTIONS_CALL.DATA_GENERAL_SEND, dataGeneralSet);
    return () => {callSocket.off(ACTIONS_CALL.DATA_GENERAL_SEND, dataGeneralSet)};
  }, [dataGeneralSet]);

  React.useEffect(() => {
    callSocket.on(ACTIONS_CALL.CALLER_QUEUE_UP_SUCCESS, queueUpSuccess);
    return () => {callSocket.off(ACTIONS_CALL.CALLER_QUEUE_UP_SUCCESS, queueUpSuccess)};
  }, [queueUpSuccess]);

  // React.useEffect(() => {
  //   callSocket.on(ACTIONS_CALL.ICE_CANDIDATE, iceCandidateSet);
  //   return () => {callSocket.off(ACTIONS_CALL.ICE_CANDIDATE, iceCandidateSet);};
  // }, [ iceCandidateSet ]);

  // React.useEffect(() => {
  //   callSocket.on(ACTIONS_CALL.OFFER_TO_CALLER_SEND, offerSet);
  //   return () => {callSocket.off(ACTIONS_CALL.OFFER_TO_CALLER_SEND, offerSet);};
  // }, [ offerSet ]);

  // React.useEffect(() => {
  //   callSocket.on(ACTIONS_CALL.OPERATOR_CALL_START, callRun);
  //   return () => {callSocket.off(ACTIONS_CALL.OPERATOR_CALL_START, callRun)};
  // }, [ callRun ]);

  // React.useEffect(() => {
  //   callSocket.on(ACTIONS_CALL.OPERATOR_CALL_STOP, operatorCallStop);
  //   return () => {callSocket.off(ACTIONS_CALL.OPERATOR_CALL_STOP, operatorCallStop)};
  // }, [ operatorCallStop ]);

  // React.useEffect(() => {
  //   callSocket.on(ACTIONS_CALL.OPERATOR_CALL_REJECT, operatorCallRejected);
  //   return () => {callSocket.off(ACTIONS_CALL.OPERATOR_CALL_REJECT, operatorCallRejected)};
  // }, [ operatorCallRejected ]);

  // React.useEffect(() => {
  //   callSocket.on(ACTIONS_CALL.CLIENT_DISCONNECT, clientDisconnect);
  //   return () => {callSocket.off(ACTIONS_CALL.CLIENT_DISCONNECT, clientDisconnect)};
  // }, [clientDisconnect]);

  // React.useEffect(() => {
  //   callSocket.on(ACTIONS_CALL.DATA_TO_CALLER_SEND, personalDataSet);
  //   return () => { callSocket.off(ACTIONS_CALL.DATA_TO_CALLER_SEND, personalDataSet);};
  // }, [ personalDataSet ]);
  
  return React.useMemo(() => ({
  }), [
  ]);
};
