import React from 'react';

import { useMainContext } from '../main';


export interface DevicesContextInterface {
  cameraMuted: boolean;
  microphoneMuted: boolean;
  cameraList: MediaDeviceInfo[];
  microphoneList: MediaDeviceInfo[];
  cameraDeviceId: string | null;
  microphoneDeviceId: string | null;
  cameraToggle: () => void;
  microphoneToggle: () => void;
  cameraSet: (deviceId: string) => void;
  microphoneSet: (deviceId: string) => void;
  mediaStart: () => void;
  mediaStop: () => void;
};

export const useDevicesHook = (
): DevicesContextInterface => {
  const {
    localMediaStream,
    peerMediaElements,
    peerConnection,
    callUuid,
  } = useMainContext();

  const [ cameraMuted, cameraMutedSet ] = React.useState(false);
  const [ microphoneMuted, microphoneMutedSet ] = React.useState(false);
  const [ cameraList, cameraListSet ] = React.useState<MediaDeviceInfo[]>([]);
  const [ microphoneList, microphoneListSet ] = React.useState<MediaDeviceInfo[]>([]);
  const [ cameraDeviceId, cameraDeviceIdSet ] = React.useState<string | null>(null);
  const [ microphoneDeviceId, microphoneDeviceIdSet ] = React.useState<string | null>(null);

  const cameraToggle = React.useCallback(() => {
    if (localMediaStream.current === null) return;
    const videoTrack = localMediaStream.current.getTracks().find(track => track.kind === 'video');
    if (!videoTrack) return;
 
    videoTrack.enabled = cameraMuted;
    cameraMutedSet((state) => !state);
  }, [
    localMediaStream,
    cameraMuted,
  ]);

  const microphoneToggle = React.useCallback(() => {
    if (localMediaStream.current === null) return;
    const audioTrack = localMediaStream.current.getTracks().find(track => track.kind === 'audio');
    if (!audioTrack) return;
 
    audioTrack.enabled = microphoneMuted;
    microphoneMutedSet((state) => !state);
  }, [
    localMediaStream,
    microphoneMuted,
  ]);

  const cameraSet = React.useCallback(async (deviceId: string) => {
    if (microphoneDeviceId === null) return;

    cameraDeviceIdSet(deviceId);

    localMediaStream.current = await navigator.mediaDevices.getUserMedia({
      audio: {
        deviceId: microphoneDeviceId,
        echoCancellation: true,
      },
      video: {
        deviceId: { exact: deviceId },
        width: 1280,
        height: 720,
      },
    });

    const localVideoElement = peerMediaElements.current.local;
    if (localVideoElement) {
      localVideoElement.volume = 0;
      localVideoElement.srcObject = localMediaStream.current;
    }
    
    if (peerConnection.current !== null && localMediaStream.current !== null) {
        const videoTrack = localMediaStream.current.getVideoTracks()[0];
        const sender = peerConnection
          .current
          .getSenders()
          .find((s) => s.track && s.track.kind === 'video');

        if (sender === undefined || videoTrack === undefined) {
          return;
        }

        sender.replaceTrack(videoTrack);
    }
  }, [
    localMediaStream,
    peerConnection,
    peerMediaElements,
    microphoneDeviceId,
  ]);

  const microphoneSet = React.useCallback(async (deviceId: string) => {
    if (cameraDeviceId === null) return;

    microphoneDeviceIdSet(deviceId);

    localMediaStream.current = await navigator.mediaDevices.getUserMedia({
      audio: {
        deviceId,
        echoCancellation: true,
      },
      video: {
        deviceId: cameraDeviceId,
        width: 1280,
        height: 720,
      },
    });

    const localVideoElement = peerMediaElements.current.local;
    if (localVideoElement) {
      localVideoElement.volume = 0;
      localVideoElement.srcObject = localMediaStream.current;
    }
    
    if (peerConnection.current !== null && localMediaStream.current !== null) {
      const audioTrack = localMediaStream.current.getAudioTracks()[0];
      const sender = peerConnection
        .current
        .getSenders()
        .find((s) => s.track && s.track.kind === 'audio');

      if (sender === undefined || audioTrack === undefined) {
        return;
      }

      sender.replaceTrack(audioTrack);
    }
  }, [
    localMediaStream,
    peerConnection,
    peerMediaElements,
    cameraDeviceId,
  ]);

  const mediaStart = React.useCallback(async () => {
    localMediaStream.current = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: {
        width: 1280,
        height: 720,
      },
    });

    const localVideoElement = peerMediaElements.current.local;
    if (localVideoElement) {
      localVideoElement.volume = 0;
      localVideoElement.srcObject = localMediaStream.current;
    }

    localMediaStream.current.getTracks().forEach((track) => {
      if (track.kind === 'audio') {
        microphoneDeviceIdSet(track.getSettings().deviceId || null);
      }

      if (track.kind === 'video') {
        cameraDeviceIdSet(track.getSettings().deviceId || null);
      }
    });

    const devices = await navigator.mediaDevices.enumerateDevices();
    const microphones = devices.filter((device) => device.kind === 'audioinput');
    const cameras = devices.filter((device) => device.kind === 'videoinput');
    microphoneListSet(microphones);
    cameraListSet(cameras);
  }, [
    localMediaStream,
    peerMediaElements,
  ]);

  const mediaStop = React.useCallback(() => {
    if (localMediaStream.current !== null) {
      localMediaStream.current.getTracks().forEach(track => track.stop());
      localMediaStream.current = null;
    }

    if (peerConnection.current !== null) {
      peerConnection.current.close();
      peerConnection.current = null;
    }
  }, [
    localMediaStream,
    peerConnection,
  ]);

  React.useEffect(() => {
    if (!callUuid) {
      cameraMutedSet(false);
      microphoneMutedSet(false);
    }
  }, [callUuid]);
  
  return React.useMemo(() => ({
    cameraMuted,
    microphoneMuted,
    cameraList,
    microphoneList,
    cameraDeviceId,
    microphoneDeviceId,
    cameraToggle,
    microphoneToggle,
    cameraSet,
    microphoneSet,
    mediaStart,
    mediaStop,
  }), [
    cameraMuted,
    microphoneMuted,
    cameraList,
    microphoneList,
    cameraDeviceId,
    microphoneDeviceId,
    cameraToggle,
    microphoneToggle,
    cameraSet,
    microphoneSet,
    mediaStart,
    mediaStop,
  ]);
};
