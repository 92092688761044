import React from 'react';

import {
  consultationSocket,
  ACTIONS_CONSULTATIONS,
} from '@app/api';

import { useMainContext } from '../main';
import { useCallContext } from '../call';


export interface ConnectionContextInterface {
};

export const useConnectionHook = (
): ConnectionContextInterface => {
  const { consultationUuid } = useMainContext();

  const {
    callerReady,
    consultationStart,
    offerSet,
    iceCandidateSet,
    consultantDevicesSet,
    consultantDisconnect,
    consultantLeave,
  } = useCallContext();

  React.useEffect(() => {
    if (callerReady) {
      consultationSocket.emit(ACTIONS_CONSULTATIONS.CALLER_CONNECT, { consultationUuid });
    }

    return () => {
      if (callerReady) consultationSocket.emit(ACTIONS_CONSULTATIONS.CALLER_LEAVE);
    }
  }, [
    consultationUuid,
    callerReady,
  ]);

  React.useEffect(() => {
    consultationSocket.on(ACTIONS_CONSULTATIONS.CALL_READY, consultationStart);
    return () => {consultationSocket.off(ACTIONS_CONSULTATIONS.CALL_READY, consultationStart)};
  }, [consultationStart]);

  React.useEffect(() => {
    consultationSocket.on(ACTIONS_CONSULTATIONS.ICE_CANDIDATE, iceCandidateSet);
    return () => {consultationSocket.off(ACTIONS_CONSULTATIONS.ICE_CANDIDATE, iceCandidateSet);};
  }, [ iceCandidateSet ]);

  React.useEffect(() => {
    consultationSocket.on(ACTIONS_CONSULTATIONS.OFFER_TO_CALLER_SEND, offerSet);
    return () => {consultationSocket.off(ACTIONS_CONSULTATIONS.OFFER_TO_CALLER_SEND, offerSet)};
  }, [offerSet]);

  React.useEffect(() => {
    consultationSocket.on(ACTIONS_CONSULTATIONS.CONSULTANT_DEVICES_SET, consultantDevicesSet);
    return () => {consultationSocket.off(ACTIONS_CONSULTATIONS.CONSULTANT_DEVICES_SET, consultantDevicesSet)};
  }, [consultantDevicesSet]);

  React.useEffect(() => {
    consultationSocket.on(ACTIONS_CONSULTATIONS.CONSULTANT_DISCONNECT, consultantDisconnect);
    return () => {consultationSocket.off(ACTIONS_CONSULTATIONS.CONSULTANT_DISCONNECT, consultantDisconnect)};
  }, [consultantDisconnect]);

  React.useEffect(() => {
    consultationSocket.on(ACTIONS_CONSULTATIONS.CONSULTANT_LEAVE, consultantLeave);
    return () => {consultationSocket.off(ACTIONS_CONSULTATIONS.CONSULTANT_LEAVE, consultantLeave)};
  }, [consultantLeave]);
  
  return React.useMemo(() => ({
  }), [
  ]);
};
