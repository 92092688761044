import { ButtonsProps } from './buttons.props';
import styles from './buttons.module.scss';
import cs from 'classnames';


export const ButtonsBrick = ({
  children,
  vertical = false,
}: ButtonsProps) => {
  const classnames = cs(
    { [styles['buttons--vertical']]: vertical },
    { [styles['buttons--default']]: !vertical },
  );

  return (
    <div className={classnames}>{children}</div>
  );
};
