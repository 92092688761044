import { FormQueueUpFeature, PageBaseLayout } from '@app/components'


export const HomePage = () => {
  return (
    <PageBaseLayout>
      <FormQueueUpFeature />
    </PageBaseLayout>
  );
};
