import { red } from '@mui/material/colors';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { useAppStore } from '@app/stores';
import { greyColor } from '@app/helpers';


export const ThemeProviderPart = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const { themeMode } = useAppStore();
  const theme = createTheme({
    typography: {
      // h1: {
      //   fontWeight: 500,
      // }
    },
    palette: {
      mode: themeMode,
      primary: {
        main: '#556cd6',
      },
      secondary: {
        main: '#19857b',
      },
      error: {
        main: red.A400,
      },
      background: {
        default: themeMode === 'light' ? greyColor[200] : greyColor[1000],
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
