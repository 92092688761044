import {
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

import {
  CallPage,
  ConsultationPage,
  HomePage,
  NotFoundPage,
} from '@app/components';


export const RouterOutletPart = () => {
  const router = createBrowserRouter([
    { path: '/', element: <HomePage /> },
    { path: '/consultation/:uuid', element: <ConsultationPage /> },
    { path: '/call/:uuid', element: <CallPage /> },

    { path: '*', element: <NotFoundPage /> },
  ]);

  return (
    <RouterProvider router={router} />
  );
};