import { ButtonLoadingBrick, ButtonsBrick, CardDataComposition, FieldPhoneBlock, FieldSnilsBlock, FieldTextBlock, FormCenteredBlock, QueueAddIcon } from '@app/components';
import { useMainContext } from '../../context';

export const MainComponent = () => {
  const {
    operatorListLen,
    queueLen,
    formDirty,
    formIsSubmitting,
    formValue,
    onSubmit,
    changeFormValue,
    errorSet,
  } = useMainContext();
  
  return (
    <CardDataComposition
      title={`Операторов в сети: ${operatorListLen} Человек в очереди: ${queueLen}`}
    >
      <FormCenteredBlock
        onSubmit={onSubmit}
        buttons={
          <ButtonsBrick>
            <ButtonLoadingBrick
              onClick={onSubmit}
              disabled={!formDirty}
              loading={formIsSubmitting}
              variant="contained"
              size="large"
              startIcon={<QueueAddIcon />}
              fullWidth
            >
              Встать в очередь
            </ButtonLoadingBrick>
          </ButtonsBrick>
        }
      >
        <FieldTextBlock
          label="Фамилия"
          name="lastName"
          value={formValue.lastName}
          onChange={changeFormValue}
          error={errorSet('lastName')}
          required
        />
        <FieldTextBlock
          label="Имя"
          name="firstName"
          value={formValue.firstName}
          onChange={changeFormValue}
          error={errorSet('firstName')}
          required
        />
        <FieldTextBlock
          label="Отчество"
          name="secondName"
          value={formValue.secondName}
          onChange={changeFormValue}
          error={errorSet('secondName')}
          required
        />
        <FieldPhoneBlock
          label="Телефон"
          name="phone"
          value={formValue.phone}
          onChange={changeFormValue}
          error={errorSet('phone')}
          required
        />
        <FieldSnilsBlock
          label="СНИЛС"
          name="snils"
          value={formValue.snils}
          onChange={changeFormValue}
          error={errorSet('snils')}
          required
        />
      </FormCenteredBlock>
    </CardDataComposition>
  );
}
