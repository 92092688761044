import { Link } from "react-router-dom";
import { Typography } from '@mui/material';

import styles from './not-found.module.scss';
import { PageCenteringLayout } from '@app/components/layouts';


export const NotFoundPage = () => {
  return (
    <PageCenteringLayout>
      <div className={styles['not-found-page']}>
        <div className={styles['not-found-page__content']}>
          <div className={styles['not-found-page__emoji']}></div>
          <Typography variant="h1">🤷‍♂️ Упс!</Typography>
          <Typography variant="body1">Такой страницы не существует в текущей реальности..</Typography>
          <div className={styles['not-found-page__links']}></div>
          <Link to="/">На главную</Link>
        </div>
      </div>
    </PageCenteringLayout>
  );
};
