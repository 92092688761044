import { WrapperProps } from './wrapper.props';
import styles from './wrapper.module.scss';


export const WrapperComponent = ({
  children,
}: WrapperProps) => {
  return (
    <div className={styles['wrapper']} id="call-wrapper">
      {children}
    </div>
  );
}
